export function mainFunction() {
  // Your JavaScript code here
  window.addEventListener("scroll", function () {
    var element = document.getElementById("navBar");
    var element2 = document.getElementById("SearchBar");
    var scrollPosition =
      window.pageYOffset || document.documentElement.scrollTop;

    if (scrollPosition > 300) {
      element.classList.add("fixed");
      element2.classList.add("hideV");
    } else {
      element.classList.remove("fixed");
      element2.classList.remove("hideV");
    }
  });
}
