import suc from "./suc.png";
import fbIco from "./facebook-button.png";
import banner from "./banner.jpg";
import "./Components/css/App.css";
import "./Components/css/Style.css";
import "./Components/css/Navbar.css";
import "./Components/css/HomeComp.css";
import { Navbar } from "./Components/jsx/Navbar";
import { mainFunction } from "./Components/js/main.js";
import React, { lazy, Suspense } from "react";
import ErrorBoundary from "./Components/js/ErrorBoundary";
import { LodingScreen } from "./Components/jsx/LodingScreen";
const HomeTopComp = lazy(() => import("./Components/jsx/HomeTopComp"));

// const ComponentB = lazy(() => import("./ComponentB"));
// const HomeTopComp = lazy(() => import(".p"));

function App() {
  mainFunction();

  return (
    <>
      <ErrorBoundary>
        <Suspense fallback={<LodingScreen />}>
          <Navbar />

          <HomeTopComp />

          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />

          <div className="App">
            <meta
              name="description"
              content="Experience the enchanting allure of our handmade Dhaka Saree, meticulously crafted in Nepal with high-quality threads. This traditional masterpiece exudes elegance and grace, celebrating the rich heritage of the region. Embrace timeless tradition and exceptional craftsmanship with this exquisite home-made creation."
            />
            <meta
              name="keywords"
              content="Dhaka saree, home made, high quality thread, Nepal, tradition, handcrafted, exquisite, enchanting allure, meticulously crafted, rich heritage, elegance, grace, timeless, exceptional craftsmanship, traditional masterpiece, celebrating, region, timeless tradition, exceptional, craftsmanship, artistry, vibrant colors, soft texture, glamorous, occasion, support skilled artisans, unique, fusion, contemporary fashion, individuality, luxury, preserve, promotion, cultural event, wedding, diva, drapes beautifully, silhouette, statement, skill, livelihoods, soft, smooth, comfortable, unique story, blend, motifs, modern appeal, support, homegrown craftsmanship, saree"
            />

            <header className="App-header">
              <h2>WEBSITE UNDER CONSTRUCTION</h2>
              <div className="fbCon">
                <a href="https://www.facebook.com/profile.php?id=100091128786870">
                  <img src={fbIco} alt="Facebook Button" />
                </a>
              </div>
            </header>
            <img src={banner} className="banner" alt="Banner" />
            <header className="App-header2">
              <img src={suc} className="App-logo" alt="logo" />
              <h1>WEBSITE UNDER CONSTRUCTION</h1>
              <div className="fbCon">
                <a href="https://www.facebook.com/profile.php?id=100091128786870">
                  <img src={fbIco} alt="Facebook Button" />
                </a>
              </div>
            </header>
          </div>
        </Suspense>
      </ErrorBoundary>
    </>
  );
}

export default App;
