import { AiOutlineShoppingCart } from "react-icons/ai";
import { BsPersonCircle } from "react-icons/bs";
import { BsHeart } from "react-icons/bs";
import React from 'react'

export const Navbar = () => {
  return (
    <>
    <div className='navBar' id="navBar">
      <div className="LogoArea">
        <img src="image/logo.png" alt="" />
        <p>Dhaka Udhyog <br /> Pasal</p>
      </div>
      <div className="button_collection">
        <div className="btnitems btnR1"><div className="bubble">5</div><BsHeart/></div>
        <div className="btnitems btnR2"><div className="bubble2">55</div><AiOutlineShoppingCart/></div>
        <div className="btnitems btnR3"><BsPersonCircle/></div>
      </div>
      
      <div className="SearchArea" id="SearchBar">
        <button><i class="fa-solid fa-magnifying-glass"></i></button>
        <input type="search" placeholder='Search items...'/>
      </div>
    </div>
    </>
  )
}
