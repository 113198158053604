import React from 'react'

export const LodingScreen = () => {
  return (
    <>

    <div className="container">
            <div class="load">
        <hr />
        <hr />
        <hr />
        <hr />
        </div>
    </div>


    </>
  )
}
